@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  overflow-y: hidden;
}

body {
  margin: 0;
}

@font-face {
  font-family: 'centuryGhotic';
  src: url('assets/font/CenturyGothic.ttf');
}

button {
  @apply h-9 rounded bg-white px-3 text-sm font-medium uppercase text-black shadow-md;
  @apply focus:outline-none;

  &.btn-primary {
    @apply border border-smg-green bg-smg-green text-white;
  }

  &.btn-secondary {
    @apply border border-smg-green bg-smg-gray text-black;
  }

  &:disabled {
    @apply cursor-not-allowed opacity-25;
  }

  &.btn-small {
    @apply h-7 text-xs font-bold;
  }
}

.btn-blue {
  @apply h-9 rounded bg-white px-3 text-sm font-medium uppercase text-black shadow-md focus:outline-none focus:ring-0;
  @apply focus:outline-none;

  &.btn-primary {
    @apply border-0 bg-smg-blue text-white;
  }

  &.btn-secondary {
    @apply border border-smg-blue bg-white text-smg-blue;
  }

  &.btn-small {
    @apply h-7 text-xs font-bold;
  }
}

.input-border {
  input {
    @apply h-12 rounded border border-smg-darkGray focus:outline-none focus:ring-0;
  }
}

input:not([type='color']):not([type='checkbox']),
textarea,
select {
  @apply h-7 w-full border p-1 text-xs;
}

input,
textarea,
select {
  @apply z-10 focus:outline-none focus:ring-2 focus:ring-smg-green;

  &.ng-invalid {
    @apply border-red-600;
    @apply focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-red-600;
  }
}

// https://www.smashingmagazine.com/2021/09/simplifying-form-styles-accent-color/#alternatives
input:is([type='checkbox']) {
  @apply h-4 w-4 appearance-none rounded-sm border-2 border-solid bg-white focus:ring-0;
  border-color: #8d8d8d;

  &:checked {
    @apply border-2 border-solid border-smg-blue bg-smg-blue bg-no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='-10 -10 64.56 64.56'%3E%3Cpath fill='white' d='M23.297 38.74a6.563 6.563 0 0 1-10.16.499L1.308 26.112a5.083 5.083 0 1 1 7.551-6.805l8.369 9.288a.617.617 0 0 0 .956-.047L35.386 5.217a5.083 5.083 0 1 1 8.181 6.032L23.297 38.74z'/%3E%3C/svg%3E");
  }

  &:disabled {
    @apply border-2 border-solid bg-no-repeat opacity-50;
    background-color: #f0f0f0;
    border-color: #bbbbbb;

    &:checked {
      @apply bg-white;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='-10 -10 64.56 64.56'%3E%3Cpath fill='darkgray' d='M23.297 38.74a6.563 6.563 0 0 1-10.16.499L1.308 26.112a5.083 5.083 0 1 1 7.551-6.805l8.369 9.288a.617.617 0 0 0 .956-.047L35.386 5.217a5.083 5.083 0 1 1 8.181 6.032L23.297 38.74z'/%3E%3C/svg%3E");
    }
  }

  &:indeterminate {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='-2 2 15 15'%3E%3Cpath fill='darkgray' d='M0 8 11 8 11 12 0 12 0 8'/%3E%3C/svg%3E");
  }
}

.form-field {
  @apply mt-1;

  label {
    @apply h-7 text-xs;

    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.form-field-col {
  @apply mt-1 flex h-16 flex-col;

  &.field-textarea {
    @apply h-32;

    textarea {
      @apply h-24 resize-none;
    }
  }

  &.field-datetime {
    @apply h-24;
  }

  label {
    @apply text-sm;
  }
}

.form-field,
.form-field-col {
  .message-block {
    @apply text-smg-lightRed;

    &:empty {
      @apply hidden;
    }
  }

  .error-message {
    @apply hidden;

    &:first-child {
      @apply block text-tiny;
    }
  }
}

.close-panel {
  @apply absolute text-2xl font-bold leading-none text-black;
  top: -12px;
  right: -8px;

  &:hover {
    @apply cursor-pointer text-smg-darkGray;
  }
}

.close-modal {
  @apply absolute text-3xl font-bold leading-none text-white;
  top: 0;
  right: 8px;

  &:hover {
    @apply cursor-pointer text-smg-darkGray;
  }
}

.disabled {
  @apply pointer-events-none opacity-50;
}

@mixin backdrop($z-index: 1000) {
  @apply absolute h-full w-full;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: $z-index;
}

.text-no-overflow {
  @apply overflow-hidden text-ellipsis whitespace-nowrap;
}

.filter-blue {
  filter: invert(55%) sepia(30%) saturate(7000%) hue-rotate(165deg) brightness(95%) contrast(100%);
}

.clear-scroll::-webkit-scrollbar {
  width: 16px;
}

.clear-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.clear-scroll::-webkit-scrollbar-thumb {
  background-color: #c3c1c1;
  border-radius: 12px;
  border: 8px solid transparent;
  border-left: 8px solid transparent;
  border-right: 0px solid transparent;
  background-clip: content-box;
}

.clear-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #b4e1f2;
}
